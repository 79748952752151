export const StudentCheckIcon = () => {
  return (
    <svg
      viewBox="0 0 349 295"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Wise-Proctor"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="18.-Student-Successfully-InClass"
          transform="translate(-2288.000000, -1324.000000)"
          fill="#FFFFFF"
          fill-rule="nonzero"
        >
          <path
            d="M2611.94359,1475.9697 L2637,1501.17879 L2520.12974,1619 L2458.02564,1556.42424 L2483.08205,1531.21515 L2520.12974,1568.40303 L2611.94359,1475.9697 M2431.17949,1324 C2470.71741,1324 2502.76923,1356.01842 2502.76923,1395.51515 C2502.76923,1435.01188 2470.71741,1467.0303 2431.17949,1467.0303 C2391.64156,1467.0303 2359.58974,1435.01188 2359.58974,1395.51515 C2359.58974,1356.01842 2391.64156,1324 2431.17949,1324 M2431.17949,1359.75758 C2411.41053,1359.75758 2395.38462,1375.76679 2395.38462,1395.51515 C2395.38462,1415.26352 2411.41053,1431.27273 2431.17949,1431.27273 C2450.94845,1431.27273 2466.97436,1415.26352 2466.97436,1395.51515 C2466.97436,1375.76679 2450.94845,1359.75758 2431.17949,1359.75758 M2431.17949,1484.90909 C2443.34974,1484.90909 2458.02564,1486.51818 2474.31231,1489.55758 L2444.42359,1519.41515 L2431.17949,1518.87879 C2378.0241,1518.87879 2322.00513,1544.98182 2322.00513,1556.42424 L2322.00513,1576.09091 L2432.96923,1576.09091 L2466.97436,1610.06061 L2288,1610.06061 L2288,1556.42424 C2288,1508.86667 2383.39333,1484.90909 2431.17949,1484.90909 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};
